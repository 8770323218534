<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <div class="grid-x">
            <div class="cell small-3">
              <h1>
                Send message to
              </h1>
            </div>
            <div class="cell small-4">
              <select v-model="receiver">
                <option
                  v-for="(receiver, index) in receivers"
                  :key="`rcvr_${index}`"
                  :value="receiver">
                  {{ receiver.email_address }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form class="file-form">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Message<sup class="text-red">*</sup></label>
                <vue-editor
                  id="messageEditor"
                  v-bind:editorToolbar="customToolbar"
                  v-model="message.messageText"
                  placeholder="Message text"
                  rows="12">
                </vue-editor>
              </div>
            </div>
            <div class="grid-x grid-padding-x pad-top">
              <div class="cell small-12 medium-6">
                <label>Click the button below to select a file to attach</label>
                <br />
                <div class="file"></div>
                <div><p>{{ filename }}</p></div>
                <div class="UppyProgressBar"></div>
              </div>
            </div>
            <div class="grid-x grid-padding-x pad-top">
              <div class="cell small-12 medium-6">
                <button class="button">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="alertMessage.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="reload()">X</span>
      <p class="message-text">{{ alertMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'SendMessage',
  data() {
    return {
      receivers: [],
      receiver: {},
      user: {},
      message: {},
      alertMessage: '',
      filename: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getUser() {
      const userId = this.$route.params.id;
      axios.get(`/users/getReceivers/${userId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.receivers = response.data.receivers;
        });
    },
    saveMessage(result) {
      const postData = {};
      postData.message = this.message.messageText;
      postData.file = result;
      postData.receiverId = this.receiver.id;
      postData.senderId = this.authData.userId;
      axios.post(`/messages/add.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, postData)
        .then((response) => {
          if (response.data.response === true) {
            this.alertMessage = 'The message has been sent successfully';
          } else {
            this.alertMessage = 'The message could not be sent - please check the details';
          }
        });
    },
    reload() {
      // eslint-disable-next-line
      location.reload();
    },
    clearMessage() {
      this.alertMessage = '';
    },
  },
  mounted() {
    this.getUser();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.file-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInput',
        target: '.file',
        pretty: true,
        inputName: 'attachment',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: '5e6ae609d1d44035886a33ae28ec3307',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveMessage(result);
      });
  },
};
</script>
